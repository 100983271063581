import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography} from '@mui/material';
import ReactPlayer from 'react-player';
import v0 from './first_design.jpg';
import v0_open from './first_design_open.jpg';

const PREFIX = 'Chuck';

const classes = {
  img: `${PREFIX}-img`,
  links: `${PREFIX}-links`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.links}`]: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  [`& .${classes.img}`]: {
    padding: "10px",
    marginTop: "10px",
    margin: "auto",
    maxWidth: "40%"
  }
}));

const Chuck = () => {

  return (
    <Root>
    <h1>Corn Hole Unified Cognitive Kernel (CHUCK)</h1>
    <ReactPlayer
      url="https://youtube.com/embed/gog6uhCX9ao?rel=0"/>
    <Typography>

      After graduating from Wentworth Institute of Technology, I joined a small
      research group (Artificial Intelligence Robotics) in the Computer Science department at Wentworth advised
      by <a className={classes.links} target="_blank" rel={"noreferrer"} href="https://derbinsky.info/?s="> Nate Derbinsky</a>.
      We received a $5000 internal grant to develop an embodied cognitive system
      capable of learning to play corn hole, a lawn game where players switch off
      throwing beanbags at angled platforms separated by a few meters. 

    <br/>
    <br/>
      I began by modelling and simulating a single bean bag trajectory and collision
      with the platform and then evaluated the performance of TD learning, a reinforcement learning model.
      The model tried to learn the power input to the servo motor and the time at
      which to release the bean bag.
      Once I determined it would be feasible to learn how these features, I developed
      a robot arm using CAD. I went through a few different iterations, but decided to
      continue with this design.
    </Typography>
    <Typography>
      Version 0 for CHUCK arm. <br/>
      <img className={classes.img} src={v0} alt="cad version 0"/>
      <img className={classes.img} src={v0_open} alt="cad version 0"/>
    </Typography>
    <Typography>
      Using randomly generated power inputs and release times, I trained the model.
      Due to the sheer number of required training examples, it became tedious 
      manually loading the arm, picking up the bean bags, and entering the reward. 
      Thus, I created a dataset to assist with evaluating the reward, based on the 
      location of the bean bag after the arm threw it.
    </Typography>
    </Root>
  );
}

export default Chuck;
