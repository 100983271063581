import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, IconButton, Typography} from '@mui/material';
import Article from '@mui/icons-material/Article';
import VideoPopUp from './VideoPopUp';
import BibtexPopUp from './BibtexPopUP';

const PREFIX = 'ResearchCard';

const classes = {
  researchItem: `${PREFIX}-researchItem`
};

//const StyledGrid = styled(Grid)((
//  {
//    theme
//  }
//) => ({
//  [`& .${classes.researchItem}`]: {
//    padding: "0px",
//  }
//}));


const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.researchItem}`]: {
    display: "flex",
    background: theme.palette.background.paper,
    borderRadius: 10,
    justifyContent: "space-between"
  },
}));


function createVids (props) {
  return (
    <Grid container>
        {props.map(vid => 
          <Grid key={vid}  item>
            <VideoPopUp vidLink={vid}/>
          </Grid>
        )}
    </Grid>
  );
}
  //const bibFileEntry = parseBibFile(props).getEntry('frascaetal2020hri');
  //console.log(bibFileEntry.getField("TITLE"));
  //console.log(bibjson);
    //{console.log(bibFileEntry)}
    //{bibFileEntry.getFieldAsString("TITLE")}
    //<br/>
    //{bibFileEntry.getFieldAsString("BOOKTITLE")}
    //<br/>
    //{bibFileEntry.getFieldAsString("AUTHOR")}


function createPublications (props) {
  return ( 
    <Grid container direction="column">
      {props.map(pub =>
        <Grid key={pub.id} item>
        <>
        {pub.title}
        <br/>
        {pub.booktitle}, {pub.year}
        <br/>
        {pub.author}
        <br/>
            <IconButton target="_blank" href={pub.link}>
              <Article/>
            </IconButton>
          </>
        <BibtexPopUp bibinfo={pub}/>
        </Grid>
      )}
    </Grid>
  )
}


function ResearchCard (props) {
  return (
    <StyledGrid key={props.name} item xs={12} className={classes.researchItem}>
      <Typography variant='h3'>
        {props.name}
      </Typography>
      <hr/>
      <Typography variant='h5'>
      Overview
      </Typography>
        {props.overview}
      <hr/>
      <Typography variant='h5'>
      Videos
      </Typography>
      {createVids(props.videos)}
      <hr/>
      <Typography variant='h5'>
        Publications
      </Typography>
    {createPublications(props.publications)}
    </StyledGrid>
  );
}
//      {props.publications.map(pub =>
//          <Typography key={pub}>
//            {pub}
//            <IconButton>
//              <Article/>
//            </IconButton>
//            
//          </Typography>
//    )}

export default ResearchCard;
//import * as React from 'react';
//
//import { Box, Button, Grid, IconButton, Modal, Popover, Typography, makeStyles } from '@mui/material';
//
//export default function BasicPopover(props) {
//  const [anchorEl, setAnchorEl] = React.useState(null);
//
//  const handleClick = (event) => {
//    setAnchorEl(event.currentTarget);
//  };
//
//  const handleClose = () => {
//    setAnchorEl(null);
//  };
//
//  const open = Boolean(anchorEl);
//  const id = open ? 'simple-popover' : undefined;
//
//  return (
//    <div>
//      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
//        Open Popover
//      </Button>
//      <Popover
//        id={id}
//        open={open}
//        anchorEl={anchorEl}
//        onClose={handleClose}
//        anchorOrigin={{
//          vertical: 'top',
//          horizontal: 'left',
//        }}
//      >
//        <Typography sx={{ p: 2 }}> 
//        <ReactPlayer url={props.videos}/>
//    </Typography>
//      </Popover>
//    </div>
//  );
//}
