import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid,  Typography } from '@mui/material';
import ResearchCard from './ResearchCard';
import researchProjects from './ResearchProjects';
const PREFIX = 'Research';

const classes = {
  researchContainer: `${PREFIX}-researchContainer`,
  researchItem: `${PREFIX}-researchItem`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.researchContainer}`]: {
    height: '100%',
  },

  [`& .${classes.researchItem}`]: {
    margin: 5,
  }
}));

const Research = () => {

  return (
    <StyledGrid className={classes.researchContainer} container spacing={5} justify="center">
      <Grid item xs={12} className={classes.researchItem}>
        <Typography variant='h3'> Research Overview </Typography>

        The goal of my research is to develop intelligent robots which are more versatile by enabling them to 
        learn, assess, and modify tasks online.
      </Grid>
      {researchProjects.map(card => ResearchCard(card))}
    </StyledGrid>
  );
}

export default Research;
