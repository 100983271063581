var researchProjects = [
  {
    name : "Performance Assessment",
    overview:
      "Understanding what robots can or cannot do is essential for fluid human-robot interactions as well as effective robot task performance. Yet, it is often difficult if not impossible for humans to make inferences about a robot's expected performance on a task they have not seen the robot perform because they lack detailed information about the robot's action success and failure probabilities.  We propose a self-assessment framework which enables a robot to estimate how well it will be able to perform a known or possibly novel task. The robot simulates the task to generate a state distribution of possible outcomes and determines (1) the likelihood of overall success, (2) then most probable failure location, and (3) the expected time to task completion.",
    publications : [
      {
        id : "frasca2022ral",
        type : "@article",
        title : "A Framework for Robot Self-Assessment of Expected Task Performance",
        author : "Tyler Frasca and Matthias Scheutz",
        booktitle : "IEEE Robotics and Automation Letters",
        year : "2022",
        volume : "7",
        number : "4",
        pages : "12523-12530",
        doi : "10.1109/LRA.2022.3219024",
        link : "https://hrilab.tufts.edu/publications/frascascheutz2022ral.pdf"
      },

      { 
          id : "frascaetal2020hri",
          type : "@misc",
          title : "'Can you Do This?' Self-Assessment Dialogues with Autonomous Robots Before, During, and After a Mission",
          author : "Tyler Frasca and Ravenna Thielstrom and Evan Krause and Matthias Scheutz",
          booktitle : "HRI Workshop on Assessing, Explaining, and Conveying Robot Proficiency for Human-Robot Teaming",
          year : "2020",
          link : "http://hrilab.tufts.edu/publications/frascaetal2020hri.pdf",
        },
    ],
    videos : [
      "https://youtu.be/TOccnYz8r-s",
      "https://youtu.be/gqEqH00JzRI",
    ]
  },
  {
    name : "One-Shot Learning",
    overview :
    "One-shot learning techniques have recently enabled robots to learn new objects and actions from natural language instructions.  We significantly extend this past work to one-shot interaction learning, from both natural language instruction and demonstration, where a robotic learner not only learns the actions appropriate for its role in the interaction, but also the roles of the other interactors. The resulting knowledge can be used immediately such that the robot can assume any role of the learned interaction, to the extent that it can perform the required actions.  We demonstrate the operation of the integrated architecture in a handover task in real-time on a robot.",
    publications :  [
      {
        id : "frascaetal2018acs",
        type : "@article",
        title : "One-Shot Interaction Learning from Natural Language Instruction and Demonstration",
        author : "Tyler Frasca and Bradley Oosterveld and Evan Krause and Matthias Scheutz",
        booktitle : "Advances in Cognitive Systems",
        year : "2018",
        volume : "6",
        number : "",
        pages : "159-176",
        doi : "10.1109/LRA.2022.3219024",
        link : "http://www.cogsys.org/journal/volume6/article-6-11.pdf",
      },
      {
        id : "scheutzetal17aamas",
        type : "@inproceedings",
        author : "Matthias Scheutz and Evan Krause and Brad Oosterveld and Tyler Frasca and Robert Platt",
        title : "Spoken Instruction-Based One-Shot Object and Action Learning in a Cognitive Robotic Architecture",
        booktitle : "Proceedings of the 16th International Conference on Autoomous Agents and Multiagent Systems",
        link : "https://hrilab.tufts.edu/publications/scheutzetal17aamas.pdf",
      }
    ],
    videos : [
      "https://youtu.be/t83OovAxcCY",
      "https://youtu.be/pWXGOSnG52g",
      ]
  },
  {
    name : "Task Modification",
    overview:
    "Much research effort in HRI has focused on how to enable robots to learn new skills from observations, demonstrations, and instructions. Less work, however, has focused on how skills can be corrected if they were learned incorrectly, adapted to changing circumstances, or generalized/specialized to different contexts. In this paper, a skill modification framework is introduced that allows users to modify a robot’s stored skills quickly through instructions to (1) reduce inefficiencies, (2) fix errors, and (3) enable gen eralizations, all in a way for modified skills to be immediately available for task performance. A thorough evaluation of the implemented framework shows the operation of the algorithms integrated in a cognitive robotic architecture on different fully autonomous robots in various HRI case studies. An additional online HRI user study verifies that subjects prefer to quickly modify robot knowledge in the way we proposed in the framework.",
    publications :  [
      {
        id : "frascaetal2021aaai",
        type : "@inproceedings",
        title : "Enabling Fast Instruction-Based Modification of Learned Robot Skills",
        author : "Tyler Frasca and Bradley Oosterveld and Meia Chita-Tegmark and Matthias Scheutz",
        booktitle : "Proceedings of the 35th AAAI Conference on Artificial Intelligence",
        year : "2021",
        volume : "35",
        number : "7",
        pages : "6075-6083",
        doi : "10.160909/aaai.v35i7.16757",
        link : "https://hrilab.tufts.edu/publications/frascaetal2021aaai.pdf",
      },
    ],
    videos : [
      "https://youtu.be/e0er-9LCHm4",
      ]
  },
]
export default researchProjects;
        //"@inproceedings{frascaetal2020hri,title={'Can you Do This?' Self-Assessment Dialogues with Autonomous Robots Before, During, and After a Mission},author={Tyler Frasca and Ravenna Thielstrom and Evan Krause and Matthias Scheutz}, booktitle={HRI Workshop on Assessing, Explaining, and Conveying Robot Proficiency for Human-Robot Teaming}, year={2020}, link={http://hrilab.tufts.edu/publications/frascaetal2020hri.pdf}}",
