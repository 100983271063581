import React from 'react';
import { styled } from '@mui/material/styles';
import gui from './gui_control.png';
import { Grid, Typography } from '@mui/material';
const PREFIX = 'NasaSRC';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  img: `${PREFIX}-img`,
  links: `${PREFIX}-links`,
  srcimg: `${PREFIX}-srcimg`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.img}`]: {
        display: "block",
        padding: "20px",
        margin: "0 auto",
        width: '100%',
  },
  [`& .${classes.links}`]: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  [`& .${classes.srcimg}`]: {
        display: "block",
        padding: "20px",
        margin: "0 auto",
        width: '50%',
  },
}));

const NasaSRC = () => {

    return (
      <Root>
      <Grid container direction='row'>
        <Grid item xs={12} sm={7}>
          <h1>NASA Space Robotics Challenge</h1>
        </Grid>
        <Grid item xs={12} sm={5}>
          <img className={classes.srcimg} src="https://www.nasa.gov/sites/default/files/thumbnails/image/space_robotics_challenge_badge_-_robot_only.jpg" alt="src logo"/>
        </Grid>
      </Grid>

      <Typography component='div'>
      <h1>
        Overview
      </h1>
        In my first year of grad school at Tufts Univserity, I lead a team which competed  
        in <a className={classes.links} href='https://spacecenter.org/space-robotics-challenge/space-robotics-challenge-phase-1/'> NASA's Centennial Challenges: Space Robotics Challenge (SRC)</a>.
        The SRC was a virtual competition with the goal to increase autonomous capabilities of NASA's R5 Valkyrie robot for
        space exploration missions. 
      
        It consisted of two phases: 

        <h3> Phase 1 - Qualifying</h3>
        Number of Participants: 400
        <br/>
        Each team was tasked with two challenges 1) detect lights flashing on a
        control board in front of R5 and 2) walk down a path towards a door, open it, and walk through it to a finish line.
        The top 20 teams with the lowest time for the second challenge and finished the first challenge went on to compete in Phase 2.



        <h3> Phase 2 - Main Competition</h3>
        Number of Finalists: 20
        <br/>
        The competition was held in a virtual environment where each team's R5
        had to resolve the aftermath of a dust storm that damaged a Martian
        habitat. The robots had to complete three objectives: aligning a
        communications dish, repairing a solar array, and fixing a habitat leak.
        The simulation included periods of latency and bandwidth restrictions to represent the
        communications delay from Earth to Mars.

      <br/>
      <br/>
      For more information about the contest and competitors visit the following sites: 
      <a className={classes.links} href='https://spacecenter.org/space-robotics-challenge/space-robotics-challenge-phase-1/'> Brief overview and competitor list </a>
      and <a className={classes.links} href='https://www.nasa.gov/directorates/spacetech/centennial_challenges/space_robotics/about.html'>
       NASA's Centennial Challenge: Space Robotics Challenge
      </a>


      <h1>
      Development
      </h1>
        <h2>
          Main Goal
        </h2>
        Besides qualifying for the main competiion, our overarching goal for the competition was to leverage and extend the capabilities of the DIARC cognitive-robotic architecture
        so we could teach the robot how to perform the tasks online through natural language instructions.

      <h2>
        Phase 1
      </h2>
        Before utilizing DIARC, the team focused on completing the phase 1 tasks to qualify for phase 2. While another team member worked to accomplish task 1, I
        focused on completing task 2. Because I had little experience with ROS, I started off by learning enough ROS to communicate with R5 and send it movement commands.
        With a basic understanding of ROS, I developed a ROS node in python to accomplish task 2. As aforementioned, the goal of task 2 was to have R5 walk toward a closed door,
        press a button to open the door, and walk through to the other side (as seen in the figure below). 
        The teams which completed task 1 and are in the top 20 fastest completion times of task 2 would move onto phase 2. 

  {/*
        <img src="" alt="Image of Phase 1 Task 2"/>
        */}
        <br/>
        <br/>


        Since there were numerous parameters for controlling R5's gait, I decided to write a genetic algorithm to learn 
        values for the parameters which increased the stable walking speed. A genetic algorithm is a method for solving optimization problems based
        on natural selection. The algorithm repeatedly alters a population by selecting the entities which perform the best and generating a new population.
        Between each population, the algorithm selects the best performing entities and uses them as parents to produce children for the next generation.
        Each generation evolves towards an optimal generation. Occasionally, when producing children for the next generation, the algorithm introduces a random mutation
        to explore entities which may otherwise not have been explored. These mutations can help overcome local minimums and maximums.
        For the qualifying task I mainly focused on the following parameters: the step size,
        the transfer time between each step, the swing time for the leg, and the swing height of the leg. The following shows an example execution of the
        walking task.

        
        <br/>
        <br/>
          youtube video - coming shortly
        <br/>
        <br/>


      <h2>
        Phase 2
      </h2>

      For Phase 2, we began by integrating R5 with the DIARC architecture by creating a ROS node
      which communicates with both DIARC and R5. Utilizing DIARC enables us to use its rich natural
      language pipeline as well as its sophisticated goal managing and execution component. Ideally, we
      wanted to instruct the robot the entire task. To reach this goal, we
      first needed to develop actions the robot can perform including, moving R5's arm to a specific location using
      the MoveIt! motion planner, navigation, moving R5's arm a circular tragectory to rotate a
      valve.  Actions in DIARC are similar to the PDDL representation, but has a richer capabilities,
      please see this chapter on <a className={classes.links} href="https://hrilab.tufts.edu/publications/scheutzetal2018diarcchapter.pdf" target="_blank" rel="noreferrer">DIARC </a>
      for more details. 

      <br/>
        <img className={classes.img} src={gui} alt="GUI to control R5"/>
      <br/>

      Once we had a subset of the actions implemented, Evan Krause and I developed a GUI, shown
      above, so we could interact with the robot to provide assistance. The GUI consisted common actions
      for the robot to execute, a snapshot from the robot's camera, and an interactive point cloud. Due to
      bandwith restrictions, we could only occasionally caputre a snapshot of the robot's view as well as
      the point cloud. However, these views allowed us to know where the robot was in the environment and
      provide poses for navigation and manipulation waypoints.  Typically, because of the bandwidth and
      communication delay, we took a snapshot of the camera and lidar system, provided a command, then
      waited for feedback from the robot once it finished.

      <br/>
      <br/>

      Since I was still learning ROS and this wasn't my main project, we were not able to obtain our goal of 
      teaching the tasks to the robot. However, we were able to develop most of the manipulation, navigation, and perception requirements,
      albiet the actions were not fully robust. 
      Unfortunately, for the competition, I overlooked one critical aspect, namely the accruement of points. For an unkown reason, I was under
      the impression the competition would take the run with the highest score. So for the first
      attempt I wanted to
      ensure everything was working properly and ended the trial early; however, the trial happend to have one of the lowest bandwidth restrictions
      communication delays. In the end, we were able to complete the first task and part of the second and thrid tasks and finished in 11th place.

      <br/>
      <br/>

    



      </Typography>
      </Root>
    );
}

export default NasaSRC;
