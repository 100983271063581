import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ConfusionMatrix from './confusion_matrix.png';

const PREFIX = 'SignalDetection';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  img: `${PREFIX}-img`,
  instruction: `${PREFIX}-instruction`,
  links: `${PREFIX}-links`,
  math: `${PREFIX}-math`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.img}`]: {
    display: "block",
    padding: "20px",
    margin: "0 auto",
  },

  [`& .${classes.instruction}`]: {
    fontFamily: "Roboto",
    padding: "5px",
    background: theme.palette.background.paper,
  },

  [`& .${classes.links}`]: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },

  [`& .${classes.math}`]: {
    fontFamily: "Roboto",
    padding: "5px",
    background: theme.palette.background.paper,
  }
}));


const SignalDetection = () => {

  return (
    <Root>
      <h1 style={{textAlign: "center"}} > Signal Detection Experiment </h1>
      <h4> Introduction to Signal Detection Theory </h4>

    <Typography>
      Researchers and developers have used Signal Detection Theory to study the
      affect a stimulus has on people. In essence it evaluates how well a person can 
      detect a something. For example, consider a person may be in front of a control panel with 
      several buttons and sensor readings. If the readings from the sensor are
      not obvious enough, then the person might miss when it is reading critical values.
      Instead, it might be better to have a light indicating when values are critical 
      so as to grab the attention of the person. Researchers and developers can use Signal Detection Theory
      to find the minimal required signal to grab a person's attention for a given scenario.
     <br/>
     <br/>
      To find the minimal signal that still results in a high probability of correct identification, researchers and developers should have several 
      participants respond to whether a signal is present in the desired scenario. The study
      environment should be set up to match as closely as possible to the intended environment.
      The participant will then take part in several trials where the signal is or is not present.
      The participant will then respond 'yes' when they detect the signal and 'no' otherwise.
      In the example given above, the participant should respond 'yes' when they detect the light.
     <br/>
     <br/>
      The example above is fairly straightforward, the light is on or it is off. However, consider a doctor trying to 
      detect a tumor. The doctor may have some criterion or threshold for when a tumor is present. This may result in situations where 
      the doctor misses a tumor, or thinks they detected one resulting in a false alarm.
      The image below breaks the problem into two signals, the left represents the environment without the signal, 
      and the right one represents the environment with the signal. The criterion for when a person detects
      a signal results in varying overlapping thus, increasing or decreasing the miss and false alarm sizes.
    </Typography>
    <img className={classes.img} src="https://media.springernature.com/m685/springer-static/image/art%3A10.1038%2Fncpneuro0794/MediaObjects/41582_2008_Article_BFncpneuro0794_Fig1_HTML.jpg" alt=""/>

    <Typography>
      We can evaluate how well someone responds to a stimulus by calculating their receiver-operator characteristic (ROC). 
      The ROC curve shows how the rate of hits compares to the rate of false alarms as the user moves their criterion. 
      The image below shows the sensitivity of strength of a signal compared to the environment and a users criterion.
      When someone has a high criterion, they will be toward the left side of the graph and hopefully have a hire portion of
      hits than false alarms. However, as the user lowers their criterion, they are more likely to increase their number of false alarms.
      The d' value in the image represents the strength of the signal. As seen in the image, the greater the d' value, the 
      stronger the signal and higher probability of getting correct hits.
      We can calculate the strength of a signal d' by calculating the comparing the spread of the hits to the spread of the false alarms.
      This means we compare the difference between the inverse of the normal distribution of the hits to the inverse of the normal distribution of the false alarms.
      First we want to calculate the hit rate and false alarm rate.
    </Typography>
    <br/>

    <Typography className={classes.math}>
    hit_rate = hits / (hits + misses)
    <br/>
    false_alarm_rate = false_alarms / (false_alarms + correct_rejects)
    <br/>
    z(hit) = norminv(hit_rate) 
    <br/>
    z(false_alarm) = norminv(false_alarm_rate) 
    <br/>
    d' = z(hit) - z(false_alarm)
    </Typography>


    <img className={classes.img} src="https://www.researchgate.net/profile/Michael_OMahony2/publication/51091674/figure/fig2/AS:289290392227858@1445983612278/ROC-curves-for-different-d-values.png" alt={""}/>
    
    <h4> Experimental and Setup </h4>
      For my experiment I decided to evaluate a participants detection response to a buzzer
      in three different environments: a quiet room, a room with moderate background noise by
      playing music through speakers, and a room with loud background noise by playing music through speakers.
      In the three cases, an LED would light up and a buzzer may or may not beep. When the buzzer
      beeped the participant needed to respond by pressing a button signifying they heard the signal. 
    <br/>

    <h5> Hardware Setup </h5>
    <Typography>
      In order to set up this experiment, I needed some hardware, namely a LED, buzzer, button and
      microprocessor. Because I did not need a lot of I/Os and I already had one lying around, I chose to use an Arduino UNO 
      compatible <a className={classes.links} target="_blank" rel="noreferrer" href='https://www.seeedstudio.com/Seeeduino-Lotus-ATMega328-Board-with-Grove-Interface-p-1942.html'>Seeeduino Lotus</a> board.
      I then connected a Grove breakout board for the <a className={classes.links} target="_blank" rel="noreferrer"  href='https://www.seeedstudio.com/Grove-Red-LED.html'>LED</a>
      , <a className={classes.links}  target="_blank" rel="noreferrer" href='https://www.seeedstudio.com/Grove-Buzzer.html'>buzzer</a>,
      and <a className={classes.links} target="_blank" rel="noreferrer"  href='https://www.seeedstudio.com/buttons-c-928/Grove-Button.html'>button</a>.
      I connected the LED to digital pin 4, the buzzer to digital pin 5, and the button to digital pin 2 to use for interruptions.
      Although I used the Seeeduino Lotus board and the Grove breakout boards, it is not required
      and you can use other components.
    </Typography>
      
    <h5> Software Setup </h5>
    <Typography>
      With the hardware connected, I then wrote two main functions for the experiment one to calibrate
      the buzzer volume and one to run the experiment. The code can be found <a className={classes.links} target="_blank" rel="noreferrer"  href='https://github.com/tfrasca/Signal-Detection-Experiment'>here</a>.
      The program starts by calibrating the buzzer volume by sweeping the volume down from max and then up
      from 0. The participant presses the button when they cannot hear the buzzer in the former case 
      and when it hears the buzzer in the latter. The buzzer volume is calculated by taking the average
      from the two sweeps. Once the calibration is complete, the program runs the experiment. For each 
      trial it randomly generates a value 0/1 to determine if the signal will be present when the LED is on.
      The experiment does 21 trials and outputs the results at the end.
    <br/>
    <br/>
      In order to instruct the participant I have created a python script which listens to the serial port
      and outputs the instructions in the terminal. At the end the python script displays and saves the results of the
      experiment and calculates the z_hit, z_false_alarm values, d', and beta values.
    </Typography>

    <h4> Experiment </h4>
    <Typography>
      Prior to running the experiment, the Arduino board needs to be plugged into the computer. Once the 
      participant is ready, for each experiment, the instructor needs to execute the signal_detection_script.py script
      in the terminal.
    </Typography>
      <Typography className={classes.instruction}>
      python signal_detection_script.py
      </Typography>
      When the participant starts the experiment, they are greeted with and are asked to press the button 
      to start the buzzer calibration when they are ready.

      <Typography className={classes.instruction}>
      Hello, thank you for participating in my experiment.<br/>
      We will start by calibrating the buzzer volume.<br/>
      Press the button to start calibration
      </Typography>

      Once they hit the button, they are then presented with instructions for the first sweep.
      
      <Typography className={classes.instruction}>
      Instructions:<br/>
      Once you start, you will hear 3 beeps and a buzzer will gradually lower in volume.<br/>
      Press the button when you can no longer hear the buzzer.<br/>
      Press the button to begin
      </Typography>

      If they do not hit the button, then it will ask them to redo the first calibration process.
      Once the participant completes the volume down sweep, they are instructed to complete the volume
      up sweep.

      <Typography className={classes.instruction}>
      Instructions:<br/>
      Once you start, you will hear 3 beeps and a buzzer will gradually increase in volume.<br/>
      Press the button when you can hear the buzzer.<br/>
      Press the button to begin
      </Typography>

      After completing the calibration process, the participants are greeted by another message 
      instructing them to press the button when they are ready to begin the experiment.

      <Typography className={classes.instruction}>
      Awesome, now that the device is calibrated, let's get to the Experiment!!<br/>
      Instructions:<br/>
      You will complete 21 trials to detect the buzzer.<br/>
      For each trial, A light will turn on and if you hear a beep from the buzzer hit the button.<br/>
      If the buzzer does not go off, then do not hit the button.<br/>
      At the end of the trial, the light will turn off.<br/>
      Press the button to begin, you will hear 5 beeps and then the experiment will commense.<br/>
      </Typography >

      At the end of the experiment, the participant will be thanked and provided their results.

      <Typography className={classes.instruction}>
      Thank you for participating in my experiment<br/>
      End Experiment<br/>
      Total tests: 21<br/>
      Hits: 7<br/>
      Misses: 1<br/>
      False Alarms: 2<br/>
      Correct Rejects: 11<br/>
      </Typography >


    <h4>Analysis</h4>
    <Typography>
    As mentioned above, I ran the experiment under three different conditions, no background noise,
    moderate background noise, and loud background noise. Although not ideal, I was the participant.
    The following figure displays the results 
    for each case in a confusion matrix and the calculated the z_hit, z_false_alarm, d', and beta values.
    The confusion matrices rows are denoted by whether the buzzer was present or not and the columns
    denote if the button was pressed.
    </Typography>
    <img className={classes.img} src={ConfusionMatrix} alt="Confusion Matrix"/>

    <h4> Discussion </h4>
    <Typography>
    As we can see in the results above, the lower the background noise, the easier it will be to 
    detect the buzzer. In the first case, it was easy to detect the buzzer and thus, the d-prime value
    is much higher than that in the third case. The case where there was a lot of background noise
    resulted in a much lower d-prime value showing that it was much more difficult to detect the buzzer.
    In the future, I can use Signal Detection Theory, to evaluate how well my systems are working as intended.
    For example, if I were to build a robot, I would probably have a strong LED and possibly a buzzer to signal
    that the power was about to die. Basically, anytime I want to have a signal either be detected or not, 
    then I can use Signal Detection Theory to determine the strength of the signal.
    </Typography>
    </Root>
  );
}

export default SignalDetection;
