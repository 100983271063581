
//const tufts = "Tufts University, Medford, MA, USA"

const teaching = [
  {
    "name" : "Teaching and Advising",
    "items": [
      {
        "year": 2019,
        "exp": [
          "Teaching Assistant, Probabilistic Robotics, Tufts University, Medford, MA, USA",
          "Teaching Assistant, Human-Robo Interaction, Tufts University, Medford, MA, USA",
          "Research Co-Advisor, Eric Wyss (Gesture Recognition), Tufts University, Medford, MA, USA",
          "Research Co-Advisor, Pulkit Jain, (Gesture Recognition), Tufts, University, Medford, MA, USA",
        ],
      },
      {
        "year": 2018,
        "exp": [
          "Research Co-Advisor, Pulkit Jain, (Gesture Recognition), Tufts, University, Medford, MA, USA",
          "Research Co-Advisor, Rui Shi, (Robot Learning from Demonstration), Tufts, University, Medford, MA, USA",
        ],
      },
      {
        "year": 2017,
        "exp": [
          "Research Co-Advisor, Eric Wyss (NASA Space Robotics Challenge), Tufts University, Medford, MA, USA",
        ]
      }
    ]
  }
]



export default teaching;
