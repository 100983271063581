import React from 'react';
//import {makeStyles} from '@mui/material';

//const useStyles = makeStyles((theme) => ({
//  img: {
//    padding: "10px",
//    marginTop: "10px",
//    margin: "auto",
//    maxWidth: "40%"
//  },
//}));

const ConstraintLearning = () => {
  //const classes = useStyles();
  return (
    <div>
    <h1>Robot Framework for RL Constraint Learning</h1>
    <p>
    Page currently under construction, sorry for the inconvenience.
    </p>
    </div>
  );
}

export default ConstraintLearning;

