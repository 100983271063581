import React, {useState} from 'react';
import { createTheme,  styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {Grid} from '@mui/material';
import NavDrawer from './components/NavDrawer';
import Routing from './components/Routing';
import './App.css';
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {green, grey} from '@mui/material/colors';

const PREFIX = 'App';

const drawerWidth=240;

const classes = {
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  toolbar: `${PREFIX}-toolbar`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  contentContainer: `${PREFIX}-contentContainer`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    //backgroundColor: '#424242',
    //flex: 1,
    color: theme.palette.text.primary,
    //height: '100%',
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  [`& .${classes.appBar}`]: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    borderRight: "none",
  },

  [`& .${classes.contentContainer}`]: {
    flexGrow: 1,
    flex:1,
    marginTop: 50,
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      marginTop: 0,
    },
    padding: theme.spacing(3),
    //minheight:'100%',
    //height: '100%',
    //color: theme.palette.text.primary,
  }
}));


//const theme = createTheme({
//  palette: {
//    type: "dark",
//    action: {
//      active: green,
//      selected: lightBlue[300],
//    }
//  },
//  components: {
//  }
//})

const darkTheme = createTheme({
  typography: {
    fontsize: 30,
  },
  //components: {
  //  MuiCssBaseline: {
  //    styleOverrides: {
  //      body: {
  //        width: {
  //          md: "75%",
  //        }
  //      }
  //    }
  //  }
  //},
  palette: {
    mode: 'dark',
    background: {
      default: grey[800],
      paper: "#313131",
    },
    primary: {
      main:"#00758d",
    },
    secondary: green,
  },
})

function App(props) {


  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

  return (
    <>
    <ThemeProvider theme={darkTheme}>
    <CssBaseline/>
    <Root>
      <AppBar sx={{ display: {sm: 'none', xs: 'block'} }} position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Tyler Frasca
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Drawer sx={{ display: {sm: 'none'} }} 
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
         <NavDrawer drawerToggle={handleDrawerToggle}/>
        </Drawer>
        <Drawer sx={{ display: {xs: 'none', sm: 'block'} }}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <NavDrawer/>
        </Drawer>
      </nav>
      <Grid>
      <Grid item sx={{
        maxWidth: {md: 1250},
      }}>
      <main className={classes.contentContainer}
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
        }}
        >
        <Routing/>
      </main>
      </Grid>
    </Grid>
    </Root>
    </ThemeProvider>
    </>
  );
}


export default App;
//export default withTheme(App);
