import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import AboutMe from './AboutMe';
import Projects from './projects/Projects';
import Research from './research/Research';
import CV from './CV';
////import Publications from './Publications';
//
//import Blogs from './blogs/Blogs';
//import HumanMachineAutomation from './blogs/HumanMachineAutomation';
//
import EMG from './projects/emg_robot/EMG';
import Oculus from './projects/oculus_robot/Oculus';
import Chuck from './projects/chuck/Chuck';
import NasaSRC from './projects/space_robotics_challenge/SRC';
import GolfBallCamera from './projects/golfball_camera/GolfBallCamera';
import SnowBike from './projects/snow_bike/SnowBike';
import InitialsRobot from './projects/initials_robot/InitialsRobot';
import SetsRobot from './projects/sets_robot/SetsRobot';
import SignalDetection from './projects/signal_detection/SignalDetection';
import CollaborativeRobot from './projects/collaborative_robot/CollaborativeRobot';
////import ConstraintLearning from './projects/constraint_learning/ConstraintLearning';
//import OneShotLearning from './research/one_shot_learning/OneShotLearning';
////import CognitiveArchitectures from './projects/cognitive_architectures/CognitiveArchitectures';
//import ActionModification from './research/action_modification/ActionModification';
//import SelfAssessment from './research/perf_assessment/RobotSelfAssessment';

//import Persona from './Persona';

//  {/*
//
//  <Routes>
//    <Route exact path="/" component={AboutMe}/>
//    <Route exact path="/cv" component={CV}/>
//    <Route exact path="/projects" component={Projects}/>
//    <Route exact path="/research" component={Research}/>
//
//    <Route exact path="/projects/signal-detection" component={SignalDetection}/>
//    <Route exact path="/projects/nasa-src" component={NasaSRC}/>
//    <Route exact path="/projects/sets-robot" component={SetsRobot}/>
//    <Route exact path="/projects/golf-camera" component={GolfBallCamera}/>
//    <Route exact path="/projects/corn-hole-robot" component={Chuck}/>
//    <Route exact path="/projects/emg-telerobotics" component={EMG}/>
//    <Route exact path="/projects/oculus-telerobotics" component={Oculus}/>
//    <Route exact path="/projects/snow-bike" component={SnowBike}/>
//    <Route exact path="/projects/initials-robot" component={InitialsRobot}/>
//    <Route exact path="/projects/collaborative-robot" component={CollaborativeRobot}/>
//    <Route exact path="/projects/action-modification" component={ActionModification}/>
//    <Route exact path="/projects/one-shot-learning" component={OneShotLearning}/>
//    <Route exact path="/projects/robot-self-assessment" component={SelfAssessment}/>
//
//  {/*
//    <Route exact path="/blogs" component={Blogs}/>
//    <Route exact path="/blogs/human-machine-automation" component={HumanMachineAutomation}/>
//    <Route exact path={"/projects/testing"} component={Trial}/>
//    <Route exact path="/persona" component={Persona}/>
//  </Routes>
//    */}

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={ <AboutMe/> }/>
      <Route path="/projects" >
        <Route index element={<Projects/>}/>
        <Route path="emg-telerobotics" element={<EMG/>}/>
        <Route path="oculus-telerobotics" element={<Oculus/>}/>
        <Route path="signal-detection" element={<SignalDetection/>}/>
        <Route path="nasa-src" element={<NasaSRC/>}/>
        <Route path="sets-robot" element={<SetsRobot/>}/>
        <Route path="golf-camera" element={<GolfBallCamera/>}/>
        <Route path="corn-hole-robot" element={<Chuck/>}/>
        <Route path="snow-bike" element={<SnowBike/>}/>
        <Route path="initials-robot" element={<InitialsRobot/>}/>
        <Route path="collaborative-robot" element={<CollaborativeRobot/>}/>
        {/*
        <Route path="action-modification" element={ActionModification}/>
        <Route path="one-shot-learning" element={OneShotLearning}/>
        <Route path="robot-self-assessment" element={SelfAssessment}/>
        */}
      </Route>
      <Route path="/research" element={<Research/>}/>
      <Route path="/cv" element={<CV/>}/>
    </Routes>
)}
