import React from 'react';
import { Box, Button, Popover, } from '@mui/material';


export default function BibtexPopUp (props) {
  const [vidOpen, setVidOpen] = React.useState(null);
  const handleVidOpen = (event) => {
    setVidOpen(event.currentTarget);
  };
  const handleVidClose = () => {
    setVidOpen(null);
  }
  const open = Boolean(vidOpen);

  const booktitle = (props.bibinfo.type === "@article") ? "journal" : "booktitle";

  function wrapVal (val) {
    return (
      <>
      {String('{')}
      {val}
      {String('}')}
      </>
    );
  }



  return (
    <>
      <Button color={"secondary"} onClick={handleVidOpen} style={{textTransform: 'none'}}>
      
        bib
      </Button>
      <Popover
        open={open}
        anchorEl={vidOpen}
        onClose={handleVidClose}
        anchororign={{
            vertical: 'top',
            horizontal: 'center',
        }}
      >
      <Box
        sx={{
          width: 500,
          height: 300,
          margin: 2,
        }}
      >
      bibtex
      <hr/>
      {props.bibinfo.type}
      {String('{')}
      {props.bibinfo.id},
      <br/>
      title={wrapVal(props.bibinfo.title)},
      <br/>
      author={wrapVal(props.bibinfo.author)},
      <br/>
      {booktitle}={wrapVal(props.bibinfo.booktitle)},
      <br/>
      year={wrapVal(props.bibinfo.year)},
      <br/>
      volume={wrapVal(props.bibinfo.volume)},
      <br/>
      pages={wrapVal(props.bibinfo.pages)},
    <br/>
    {String('}')}
      
      </Box>
    </Popover>
    </>
  );
};

