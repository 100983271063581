
//const tufts = "Tufts University, Medford, MA, USA"

const experience = [
  "Robotics Engineer (October 2022 - present), Tufts University, Medford, MA, USA",
  "Graduate Student Research Assitant (2016-2022), Matthias Scheutz, Tufts University, Medford, MA, USA",
  "Engineering Consultant (Summer 2021), Stringking, Gardena, CA, USA",
  "R&D Engineer, NeuroFieldz (2015), Northeastern University, Boston, MA, USA",
  "Intern Systems Development (Summer 2014), Siemens Healthcare Diagnostics, Boston, MA, USA",
  "Intern Advanced Manufacturing (Summer 2013), Medtronic, North Haven, CT, USA",
]



export default experience;
