import React from 'react';
import { styled } from '@mui/material/styles';
import {Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid,  Typography,} from '@mui/material';
import {Link} from 'react-router-dom';
const PREFIX = 'GridCard';

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  media: `${PREFIX}-media`,
  cardContent: `${PREFIX}-cardContent`,
  cardBottom: `${PREFIX}-cardBottom`,
  btn: `${PREFIX}-btn`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.card}`]: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    background: theme.palette.background.paper,
    borderRadius: 10,
    justifyContent: "space-between"
  },

  [`& .${classes.title}`]: {
    display: "flex",
    flex: 2,
    wordWrap: "none"
  },

  [`& .${classes.media}`]: {
    flex: 4,
    paddingTop: '56.25%', // 16:9
  },

  [`& .${classes.cardContent}`]: {
    flex: 1,
  },

  [`& .${classes.cardBottom}`]: {
    flex: 1,
  },

  [`& .${classes.btn}`]: {
    background: theme.palette.primary.main,
    color: "#fff"
  }
}));

function createButton(props, classes) {
        //<Button className={classes.btn} component={Link} to={props.card.url}> Learn More </Button>
  if (props.card.isExternal) {
    return (
        <a target="_blank" rel="noreferrer" href={props.card.url}>
        <Button className={classes.btn}>  Learn More </Button>
        </a>
    );
  }
  return (
      //<Button className={classes.btn} component={Link} href={props.card.url}> Learn More </Button>
      <Button type="submit" className={classes.btn} component={Link} to={props.card.url}> Learn More </Button>
  );
      //<Button className={classes.btn} component={Link} to={props.car.url}> Learn More </Button>
      //<Button className={classes.btn} onClick = {() => {window.location.href = props.card.url}} > Learn More </Button>
}

function createMedia(props, classes) {
  if (props.card.imgUrl !== "") {
    var imgUrl = props.card.imgUrl;
    if (imgUrl.startsWith("https")) {
        return (
        <CardMedia
          className={classes.media}
          image = {imgUrl}
        />
      );
      } else {
        return (
        <CardMedia
          className={classes.media}
          image = {require("./projects/"+props.card.project +"/" + imgUrl)}
        />
      );
    }
  }
}

function GridCard (props) {

  return (
    <StyledGrid item xs={12} sm={6} md={4}>
    <Card className={classes.card}>
      <CardHeader className={classes.title} title={props.card.title}>
      </CardHeader>
      {createMedia(props, classes)}
      <CardContent className={classes.cardContent}>
        <Typography>
          {props.card.synopsys}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardBottom}>
        {createButton(props, classes)}
      </CardActions>
    </Card>
    </StyledGrid>
  );
}
export default GridCard;
