import React from 'react';
import { styled } from '@mui/material/styles';
import sim from './TMF_initials.PNG';
import arm from './initial_robot.jpg';
import hardware from './initial_robot_hardware.jpg';

const PREFIX = 'InitialsRobot';

const classes = {
  img: `${PREFIX}-img`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.img}`]: {
    padding: "10px",
    display: "block",
    margin: "0 auto",
    maxWidth: "75%"
  }
}));

const InitialsRobot = () => {

  return (
    <Root>
    <h1>Initials Writing Robot</h1>
    <p>
    For my advanced robotics course at Tufts University my partner and I were
    tasked with developing a robotic arm to write our initials. First, we 
    simulated the arm in LabVIEW by creating a 3 DOF arm using offset cylinders, shown below.
    The first joint was linear so the arm could move in the y direction while the second
    and third joints were revolute to move in the x direction and lift the end effector
    off the page.
    We then calculated x,y positions for each letter and had the end effector move
    to each position. Between letters, the arm lifted the end effector off the page
    and then placed it back down at the start of the next letter.
    <img className={classes.img} src={sim} alt={""}/>
    </p>

    <p>
    Once we had it working in simulation, we then built a physical robot
    to draw our initials on paper and controlled it using a National Instruments myRIO
    embedded device. We used a stepper motor for the linear joint and
    a servo motor for the two revolute joints. As you can see below, this is not
    the most elegant solution... However, it did seem to work well enough provided
    the time constraint.
    <img className={classes.img} src={arm} alt={""}/>
    </p>

    <p>
    To control the servo motors, the myRIO controller sent the joint angles directly to each motor.
    However, for the linear actuator, the myRIO controller determined how long to
    power the motor. Because the myRIO board cannot provide enough power for the
    linear actuator, we created a simple circuit using two single throw single pole
    relays to power the motor with an external battery.
    <img className={classes.img} src={hardware} alt={"Electrical components"}/>

    </p>
    </Root>
  );
}

export default InitialsRobot;
