import React from 'react';
import {Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';

const PREFIX = 'Oculus';

const classes = {
  links: `${PREFIX}-links`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.links}`]: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
}));

const Oculus = () => {
  return (
    <Root>
    <h1> Using an Oculus Rift for Telerobotics </h1>
    <ReactPlayer
      url="https://youtube.com/embed/e2ju5nULMS8?autoplay=0"/>
    <ReactPlayer
      url="https://youtube.com/embed/buM4cMC85pg?autoplay=0"/>
    <br/>
    <Typography>
      The above videos show myself and Antonio Sestito controlling a three-axis gimbal with the Oculus Rift.
      In parallel to the EMG project, we worked with the Oculus Rift to improve another aspect of telerobotic control.
      Instead of the operator wasting valuable resources such as their hands, they can naturally control the cameras using their head.
      Our hypothesis was that we would be able to improve the operator's response characteristics by replacing a mouse for the Oculus Rift.
    <br/>
    <br/>
      We were able to take advantage of the internal inertial measuring unit within the Oculus Rift to determine the position as well as the velocity and acceleration of the operator's head.
      As seen in the second video, we were able to send a video feed to the operator for an immersive feel.
    <br/>
    <br/>
      The results of our research supported our hypothesis in that the operator's response characteristics improved with the Oculus as compared to their response with a computer mouse.
      The <a className={classes.links} href="../../files/Control_for_Camera_of_a_Telerobotic_Human_Computer_Interface.pdf" download>paper</a> regarding this research has been accepted for publication to the ASME IMECE 2015 conference.
    </Typography>
    </Root>
  );
}

export default Oculus;

