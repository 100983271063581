import React from 'react';
//import {makeStyles} from '@mui/material';

//const useStyles = makeStyles((theme) => ({
//  img: {
//    padding: "10px",
//    marginTop: "10px",
//    margin: "auto",
//    maxWidth: "40%"
//  },
//}));

const CollaborativeRobot = () => {
  //const classes = useStyles();
  return (
    <div>
    <h1>Collaborative Robotic Arm</h1>
    <p>
    Page currently under construction. Check out the following link for information about the project.
    </p>
    <a href='https://www.designsbypriyanka.com/cobot'> collaborative robot </a>
    </div>
  );
}

export default CollaborativeRobot;
