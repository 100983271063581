import React from 'react';
import { styled } from '@mui/material/styles';
//import {Document, Page } from 'react-pdf';
//import Viewer from '@phuocng/react-pdf-viewer';
//import cv from './TmFrascaCV.pdf';
import {Box, Grid, ListItemText,  Typography} from '@mui/material';
import work_exp from './experience_cv';
import teach from './teaching_cv';
const PREFIX = 'CV';

const classes = {
  container: `${PREFIX}-container`,
  cv: `${PREFIX}-cv`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    flexDirection: 'column',
  },

  [`& .${classes.cv}`]: {
    alignSelf: "center",
    width: "100%",
    maxWidth:"1100px",
    height: "100vh",
  }
}));

const genListItems = (cv) => {
  return (
    <>
      {cv.map(item => 
        <Box key={item}>
          <Typography variant='h4'> {item.name} </Typography>
          {item.items.map(val =>
            <Box key={Math.random()}>
              <ListItemText>{val.year}</ListItemText>
              <ListItemText>
              {val.exp.map(ex => 
                <ListItemText key={Math.random()} >{ex}</ListItemText>
              )}
              </ListItemText>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

const genExpItems = (cv) => {
  return (
    <>
      <Typography variant='h4'> Work Experience </Typography>
      {cv.map(item => 
        <Typography key={item}>
          {item}
        </Typography>
      )}
    </>
  );
}

const genPubItems = (cv) => {
  return (
    <>
      <Typography variant='h4'> Publications </Typography>
    </>
  );
}
const CV = () => {
  const tufts = "Tufts University, Medford, MA, USA"

  return (
    <StyledGrid container className={classes.container}>
      <Grid item> 
       <Typography variant='h4'>Education</Typography>
       <>
        <ListItemText>
        Ph.D. in Computer Science and Human-Robot Interaction (2022: Advisor Matthias Scheutz), {tufts}
        </ListItemText>
        <ListItemText>
        M.S. in Computer Science (2019), {tufts}
        </ListItemText>
        <ListItemText>
        B.S. in Electromechanical Engineering (2015), Wentworth Institue of Technology, Boston, MA, USA
        </ListItemText>
        <ListItemText>
        <div id="bibtex_display"/>



        {
        genExpItems(work_exp)
        }
        {
        genPubItems()
        }
        {
        genListItems(teach)
        }
        </ListItemText>
       </>
      </Grid>


    </StyledGrid>
  );
}

export default CV;
