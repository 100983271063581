import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import SnowBikeImg from './snow_bike.jpg';

const PREFIX = 'SnowBike';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  img: `${PREFIX}-img`,
  vid: `${PREFIX}-vid`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.img}`]: {
        display: "block",
        //padding: "20px",
        margin: "0 auto",
        width: '40%',
  },

  [`& .${classes.vid}`]: {
    margin: "0 auto",
  }
}));


const SnowBike = () => {

    return (
      <Root>
      <Typography variant='h2'>
      Snow Bike
      </Typography>
      <Typography>
      <img className={classes.img} src={SnowBikeImg} alt=""/>

      For a fun side project and to learn how to weld, I decided to turn a bicycle into a snowmobike.
      I wanted to see if I would be able to ride a bike to Tufts during the snow.

      I sourced a pair of skis from the local 'swap shop' at the transfer station which allows people 
      to drop off items still in decent condition and others can take them home for free. I then proceeded to 
      measure, cut, and bend part of the angle iron to be us as mounting points. We welded the bent parts of the iron
      then welded together two of the pieces of angle iron to create a triangluar shape. Finally, we used the ski binding
      mount points to connect the triangle frame to the skis and then added a bolt between the two frames which connected
      them to the bicycle.
      During the first snowstorm of the season, we road-tested the snowmobike.
      </Typography>


      <ReactPlayer className={classes.vid} url="https://youtu.be/SbbzVxIZ-9I"/>

      <Typography>
      Though we were able to ride the snomobike, there were a few issues which could be improved in future designs.
      The main problem was trying to turn the bike; most likely due to the two skis being too close. Additionally,
      the edges of the ski were digging through the snow and hitting the pavement. The second issue was traction,
      which could be rectified by a larger rear wheel.

      For more increased steerability and stability, the next iteration of the frame would have a wider distance between the two skis and
      shocks. A future iteration could then look into designing an actual front suspension system.
      
      </Typography>
      </Root>
    );
}


export default SnowBike;
