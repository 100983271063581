import React from 'react';
import projectsList from './projectsList';
import GridCards from '../GridCards';

const Projects = (props) => {
  return (
    <GridCards cardList={projectsList} cardType={"projects"}/>
  );
}
export default Projects;
