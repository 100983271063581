import React  from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import cameraImg from './golfball_robot.png';

const PREFIX = 'GolfBallCamera';

const classes = {
  img: `${PREFIX}-img`,
  vid: `${PREFIX}-vid`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.img}`]: {
        display: "block",
        //padding: "20px",
        margin: "0 auto",
        width: '60%',
  },

  [`& .${classes.vid}`]: {
    margin: "0 auto",
  }
}));

const GolfBallCamera = () => {

  return (
    <Root>
    <Typography variant='h2'>Golf Ball Following Camera</Typography>
    <Typography>
      <img className={classes.img} src={cameraImg} alt=""/>

      Another project for one of my robotics courses was to build a camera system which
      tracks and follows a golf ball, as well as to design and build
      a miniture golf hole and club. 
      While my partner Brian developed the object recognition and calculating the offset between
      the ball and the center of the image frame, I created a 1 DoF turret so the camera follows
      the ball. The two of us designed the miniture golf hole and club and then laser cut the shapes out
      of wood.


    </Typography>
      <ReactPlayer className={classes.vid} url="https://youtu.be/MnOWrBeMnLo"/>
    </Root>
  );
}

export default GolfBallCamera;
