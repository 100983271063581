import React from 'react';
import { styled } from '@mui/material/styles';
import {AppBar, Button, ButtonGroup, Divider,  Typography} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightIcon from '@mui/icons-material/Copyright';
import {Link} from 'react-router-dom';
import lastUpdated from './lastUpdate.js';
//import headshot from "headshot.jpg";
//import cv from "TmFrascaCV.pdf"
const PREFIX = 'NavDrawer';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  personalImage: `${PREFIX}-personalImage`,
  directory: `${PREFIX}-directory`,
  directoryBtn: `${PREFIX}-directoryBtn`,
  footerBtn: `${PREFIX}-footerBtn`,
  footer: `${PREFIX}-footer`,
  edited: `${PREFIX}-edited`,
  copyRight: `${PREFIX}-copyRight`,
  copyRightIcon: `${PREFIX}-copyRightIcon`,
  copyRightText: `${PREFIX}-copyRightText`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    flexGrow: '1',
    flexShrink: 1,
  },

  [`& .${classes.personalImage}`]: {
    padding: "10px",
    marginTop: "10px",
    margin: "auto",
    width: "90%"
  },

  [`& .${classes.directory}`]: {
    width: '100%',
  },

  [`& .${classes.directoryBtn}`]: {
    fontSize: '1.5rem',
  },

  [`& .${classes.footerBtn}`]: {
    padding: '10px',
    width: "50%",
    fontSize: '1.4rem',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',

  },

  [`& .${classes.footer}`]: {
    position: 'relative',
    padding:0,
    margin:0,
  },

  [`& .${classes.edited}`]: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.0rem',
  },

  [`& .${classes.copyRight}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.copyRightIcon}`]: {
    width: '20px',
    height: '20px',
  },

  [`& .${classes.copyRightText}`]: {
    fontSize: '1.4rem',
  }
}));

//const onDownload = () => {
//  const link = document.createElement("a");
//  link.download = "tcv.pdf";
//  link.href="tcv.pdf";
//  link.click();
//};

const NavDrawer = (props) => {

  return (
    <Root className={classes.toolbar}>
      <div align="center" >
        <img className={classes.personalImage} src={"headshot.jpg"} alt="headshot"/>
        <h1> Tyler Frasca </h1>
        <ButtonGroup className={classes.directory} orientation='vertical' variant='text'>
          <Button className={classes.directoryBtn} component={Link} to="/" onClick={props.drawerToggle}>
            About
          </Button>
          <Button className={classes.directoryBtn} component={Link} to="/projects" onClick={props.drawerToggle}>
            Projects
          </Button>
    {/*
          <Button className={classes.directoryBtn} component={Link} to="/publications">
            Publications
          </Button>
          */}
          <Button className={classes.directoryBtn} component={Link} to="/research" onClick={props.drawerToggle}>
            Research
          </Button>
    {/*
          <Button className={classes.directoryBtn} component={Link} to="/cv">
            CV
          </Button>
          */}
        </ButtonGroup>
      </div>
      <div>
        <AppBar className={classes.footer}>
        <ButtonGroup variant="outlined">
          <Button className={classes.footerBtn} target="_blank" aria-label="LinkedIn" href="https://www.linkedin.com/in/tylerfrasca/">
            <LinkedInIcon/>
          </Button>
    <Button className={classes.footerBtn} target="_blank" href={"TmFrascaCV.pdf"}>
            CV
          </Button>
        </ButtonGroup>
        <Divider/>
        <div className={classes.copyRight}>
          <Typography className={classes.copyRightText}>
            <CopyrightIcon className={classes.copyRightIcon}/>
            Tyler Frasca {lastUpdated.year}
          </Typography>
        </div>
        <Typography className={classes.edited}>
          Edited {lastUpdated.month} {lastUpdated.year}
        </Typography>
      </AppBar>
      </div>
    </Root>
  );
};
export default NavDrawer;
