import React from 'react';
import { IconButton, Popover } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ReactPlayer from 'react-player';


export default function VideoPopUp (props) {
  const [vidOpen, setVidOpen] = React.useState(null);
  const handleVidOpen = (event) => {
    setVidOpen(event.currentTarget);
  };
  const handleVidClose = () => {
    setVidOpen(null);
  }
  const open = Boolean(vidOpen);
  //const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleVidOpen}>
        <YouTubeIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={vidOpen}
        onClose={handleVidClose}
        anchororign={{
            vertical: 'top',
            horizontal: 'center',
        }}
      >
      <ReactPlayer url={props.vidLink}/>
    </Popover>
    </div>
  );
};
