import React from 'react';
import { styled } from '@mui/material/styles';
import {Grid, Typography} from '@mui/material';
const PREFIX = 'AboutMe';

const classes = {
  aboutMeDiv: `${PREFIX}-aboutMeDiv`,
  links: `${PREFIX}-links`,
  pics: `${PREFIX}-pics`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.aboutMeDiv}`]: {
    //wordWrap: "wrap",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    typography: {
      fontSize: '1.4rem',
    }
  },

  [`& .${classes.links}`]: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },

  [`& .${classes.pics}`]: {
    width: "100%",
  }
}));

function AboutMe ()  {

  return (
    <StyledGrid container spacing={2} className={classes.aboutMeDiv}>
      <Grid container justify='center' className={classes.aboutMeDiv} >
        <Grid item xs={12} md={12} order={{ xs: 2, sm: 2, md: 2, lg: 2}}>
          <Typography fontSize='1.4rem'>
            
    I am a roboticist interested in developing intelligent robotic agents which can interact
    with humans on a wide range of tasks in a variety of domains.

    Although I'm most interested in the ambitious
    goal of developing household robots to assist people with a disability and give them more autonomy,
    many core requirements for intelligent agents are similar across different domains.

    They should think and act rationally, learn novel tasks/behaviors online and continuously improve overtime,
    and they must interact with humans which may require following social norms and ensure safe operations at all times.
    I'm currently interested in advancing robot manipulation capabilities including learning new behaviors online.

{/*
    To realize this goal, 

    robots need to
      learn novel tasks and behaviors and continuously improve capabilities
      introspect (assess capabilities)
      reason


          Robots need the capabillity to learn
          tasks and behaviors online 


    One key element necessary for this ambitious goal, is the software architecture which
          combines other sophisticated algorithms for perception, manipulation, goal managing, and task execution.

          Though each of these topics are of great importance for robotics, a software architecture is necessary to combine 
          these algorithms in a way that enables a robot to act intelligently across various domains.

          Robots have the potential to
          improve quality of life by assisting people with limitations and performing difficult or
          repetative tasks with precision. 
          */}

          <br/> <br/>
          
          I finished my Ph.D. in Computer Science and Human-Robot Interaction from Tufts University in August
          of 2022. My thesis focused on enabling robots to be more versatile so they can
          autonomously act across multiple domains and improve over time. I developed three frameworks that
          enable robots to learn new tasks online through natural language instructions, assess their expected task
          performance, and modify tasks online through natural language instructions. Working with Matthias Scheutz
          in the <a className={classes.links} href="https://hrilab.tufts.edu">Human-Robot Interaction Lab</a> I
          integrated these frameworks in to the DIARC cognitive-robotic architecture. I'm currently working to extend these
          frameworks to improve robot capabilities.
          {/*
          The following video demonstrates the three frameworks working in tandem.

          <ReactPlayer className={classes.vid} url='https://youtu.be/GyKnvNDBtaY'/>
          */}
          <br/> <br/>

          </Typography>
        </Grid>
        <Grid marginLeft={"auto"} item xs={12} sm={4} order={{ xs: 1, md: 1}}>
            <img className={classes.pics} src={"welcome_robot_hello.png"} alt={"Hello"}/>
        </Grid>
      </Grid>
    </StyledGrid>
    /*
          Since graduating, I've continued my research into how robots can assess their expected task performance 
          with Mattias Scheutz.


          Though I've spent the past years working on developing intelligent robotic agents, I'm itching to 
          return to developing robotic hardware. 

  Throw together some hardware and software and see what it can do.

  Robotics and artificial intelligence 
    have systems that can interacti with & collaborate 

  Robots need to adapt 


  Robots need to adapt to their environment and communicate their capabilities to teammates. 

    My primary research focus has been 

  Robotics is and advancing field that will impact society 

  It has been a vision to have robots in our everyday life to do repetative tasks and to 


  I find robotics to be fasinating because it brings together several different domains (fields)
  including electrical enginerring, mechanical engineering, computer science, and psychology. 



  Not only are the robots cool, but they are a fun / interesting to develop. they are an interesting
    problem that needs to be solved. 

  My research has primarily focused on how humans can teach robots
  new actions/tasks and modify known actions/tasks from spoken natural language instructions.
  Additionally, I am also interested in cognitive robotic architectures, specifically, how to
  design a general purpose robotic architecture.  While working in the <a className={classes.links}
  href="https://hrilab.tufts.edu"> Human-Robot Interaction Lab </a> I have helped expand the <a className={classes.links}
  href="https://hrilab.tufts.edu/publications/scheutzetal2018diarcchapter.pdf"> DIARC </a>
  (Distributed Integrated Affect Reflection Cognition) robotic architecture, by adding the ability
  to teach robots through natural language, and the action execution pipeline.

  <br/>
  <br/>
  /*}
  {/*
  Previously I received a B.S. in Electromechanical Engineering at Wentworth
  Institute of Technology. 
  Originally I was interested in controls and became interested in more
  generalized capabilities. Thus, I became interested in Artificial Intelligence.

  <br/>
  <br/>

  <img className={classes.constructionImg} src='under_construction.jpg' alt={"Website is under construction"}/>
    
    <h1> Recent Developments </h1>

      <img className={classes.constructionImg} src='https://www.alternativereg.com/under-construction.gif'/>
      <p>
       I'm in the process of updating this website; thus there is some missing content and the formatting may 
       be a little wonky.
      </p>
  </Typography>
      <div>
      new projects
      <br/>
      new publications
      <br/>
      new blogs
      <br/>
      <Button component={Link} to="/blogs/human-machine-automation">
      Human Machine Automation
      </Button>
      </div>
</Grid>
      }*/
  );
}
export default AboutMe;
