import React from 'react';
import { styled } from '@mui/material/styles';
import {Grid} from '@mui/material';
import GridCard from './GridCard';

const PREFIX = 'GridCards';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.gridContainer}`]: {
    padding: "0px",
  }
}));


function getCard (cardType, card){
  return <GridCard cardType={cardType} card={card} key={card.title}/>
}

function GridCards (props) {

  return (
    <StyledGrid className={classes.gridContainer} container spacing={3} justify="center">
      {props.cardList.map(card => getCard(props.cardType, card))}
    </StyledGrid>
  );
}

export default GridCards;
