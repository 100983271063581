import React from 'react';
import {Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';

const PREFIX = 'EMG';

const classes = {
  links: `${PREFIX}-links`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.links}`]: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
}));


const EMG = () => {
  return (
    <Root>
    <h1> Using Electromyography signals for Telerobotics</h1>
    <ReactPlayer
      url="https://youtube.com/embed/K-h8kuKL45o?autoplay=0"/>
    <br/>
    <Typography>
      The video above shows me controlling a robotic arm with electromyography (EMG) signals. 
      EMG signals are acquired from the bicep, tricep, anterior deltoid, and posterior deltoid.
      Initially, I acquired these signals at three different positions for easy classification.
      The Nearest Neighbors machine learning algorithm classifies the new data set and sends servo positions to a microcontroller for the robotic arm.
    <br/>
    <br/>
      This application is important for precision control in telerobotics. 
      Because of the stochastic nature of the world, it is unrealistic to develop an optimal system that can consider every situation.
      The ability to control parts of the robot in critical situations i.e bomb defusal, hazardous waste removal, or medical surgeries, may be the difference between the survival of individuals.
    <br/>
    <br/>
      The main focus of my research was to compare multiple supervised machine learning algorithms to see which would perform the best i.e most accurate and speed. 
      With the three initial arm positions, I was able to compare Nearest Neighbors, Random Forests, Naive Bayes, j48 decision tress, logistic regression, and support vector machine.
      The results showed Nearest Neighbors (L2 distance, 1/d weight, k=4) performed with the highest accuracy as well as sub millisecond classification.
      Please see the 2016 AAAI <a className={classes.links} target="_blank" rel="noreferrer" href="https://ojs.aaai.org/index.php/AAAI/article/view/9926">paper</a> for more information.
    </Typography>

    </Root>
  );
}

export default EMG;
