import React, {Component} from 'react';

class SetsRobot extends Component {
  render () {
    return  (
    <div>
    <h1>Sets Playing Robot</h1>
    
    <a href='https://sites.google.com/view/setrobot/home?authuser=0'> In process of updating this page.
      Check out the current page
    </a>
    </div>
  );
  }
}

export default SetsRobot;
