const projectUrl = "/projects/"
 var projectList = [
  {
    project: "src",
    title: "NASA Space Robotics Challenge",
    synopsys: "Design software for NASA's Valkyrie robot to complete tasks on Mars",
    imgUrl: "https://www.nasa.gov/sites/default/files/thumbnails/image/space_robotics_challenge_badge_-_robot_only.jpg",
    url: projectUrl + "nasa-src",
    keywords: [
      "research",
    ]
  },
  { 
    project: "collaborative_robot",
    title: "Distributed Collaborative Robotic Arm",
    synopsys: "Designed a robotic arm which allows multiple users to control",
    imgUrl: "collaborative_robot.jpg",
    url: "https://www.designsbypriyanka.com/cobot",
    isExternal: true,
    keywords: [
      "robotics",
    ]
  },
  {
    project: "sets_robot",
    title: "Sets Playing Robot",
    synopsys: "A robot that plays the game sets",
    imgUrl: "sets_robot.jpg",
    url: "https://sites.google.com/view/setrobot/home?authuser=0",
    isExternal: true,
    keywords: [
      "robotics",
    ]
  },
  {
    project: "golfball_camera",
    title: "Golf Ball Following Robot",
    synopsys: "A robot which actively tracks a golf ball",
    imgUrl: "golfball_robot.png",
    url: projectUrl + "golf-camera",
    keywords: [
      "robotics",
    ]
  },
  {
    project: "initials_robot",
    title: "Initials Writting Robot",
    synopsys: "A robot arm which draws initials",
    imgUrl: "initial_robot.jpg",
    url: projectUrl + "initials-robot",
    keywords: [
      "robotics",
    ]
  },
  {
    project: "chuck",
    title: "Chuck",
    synopsys: "A robot arm which learns to play corn hole",
    imgUrl: "throw.png",
    url: projectUrl + "corn-hole-robot",
    keywords: [
      "robotics",
    ]
  },
  {
    project: "emg_robot",
    title: "EMG Telerobotic Arm",
    synopsys: "Controlling a telerobotic system using EMG signals",
    imgUrl: "emg_robot_first.png",
    url: projectUrl + "emg-telerobotics",
    keywords: [
      "robotics",
    ]
  },
  {
    project: "oculus_robot",
    title: "Oculus Telerobotic Camera",
    synopsys: "Controlling stereoscopic cameras using the Oculus Rift",
    imgUrl: "oculus_telerobotics.png",
    url: projectUrl + "oculus-telerobotics",
    keywords: [
      "robotics",
    ]
  },
  { 
    project: "signal_detection",
    title: "Signal Detection Experiment",
    synopsys: "Create a device to measure the signal detection performance of a user",
    imgUrl: "https://media.springernature.com/m685/springer-static/image/art%3A10.1038%2Fncpneuro0794/MediaObjects/41582_2008_Article_BFncpneuro0794_Fig1_HTML.jpg",
    url: projectUrl + "signal-detection",
  },
  {
    project: "snow_bike",
    title: "Snow Bike",
    synopsys: "Created front frame using skis for a bicycle",
    imgUrl: "snow_bike.jpg",
    url: projectUrl + "snow-bike",
    keywords: [
      "misc",
    ]
  }
]
export default projectList;
